import Layout from "../components/layout-main";
import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx } from "theme-ui";

const SecurityPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      pageTitle="Security Whitepaper"
      hideSubscribe={true}
    >
      <section className="aw-accent">
        <div className="aw-content">
          <h1>Security Whitepaper</h1>
          <p>
            <i>
              Last Updated:{" "}
              {new Date(data.markdownRemark.frontmatter.date)
                .toISOString()
                .slice(0, 10)}
            </i>
            . For other questions, please email{" "}
            <a href="mailto:security@afterword.com">security@afterword.com</a>.
          </p>
          <div
            // sx={{
            //   position: "relative",
            //   paddingLeft: 350,
            //   "& h2": { scrollPaddingTop: 72, scrollBehavior: "smooth" },
            //   "& h2::before": {
            //     content: "",
            //     display: "block",
            //     position: "relative",
            //     top: "-72px",
            //     visibility: "hidden",
            //   },
            //   "& #toc": {
            //     position: "sticky",
            //     marginLeft: -350,
            //     top: 72,
            //     width: 350,
            //     height: 0,
            //   },
            // }}
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </div>
      </section>
    </Layout>
  );
};

export default SecurityPage;

export const pageQuery = graphql`
  query SecurityPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/security.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }
`;
